import React from 'react';
import './programacionDia.css'; 

function ProgramacionDia() {
    return (
        <div className="timeline-container">
            <h2 className="timeline-title">¿Nos acompañas en esta revolución de amor?</h2>
            <div className="timeline">
                <div className="event">
                    <div className="event-time">19:30</div>
                    <div className="event-dot"></div>
                    <div className="event-label">Iglesia</div>
                </div>
                <div className="event">
                    <div className="event-time">20:45</div>
                    <div className="event-dot"></div>
                    <div className="event-label">Civil</div>
                </div>
                <div className="event">
                    <div className="event-time">22:00</div>
                    <div className="event-dot"></div>
                    <div className="event-label">Celebración</div>
                </div>
            </div>
        </div>
    );
}

export default ProgramacionDia;