import './App.css';
import React from 'react';
import NavBar from './components/navBar.jsx';
import ConteoRegresivo from'./components/conteoRegresivo';
import ProgramacionDia from './components/programacionDia';
import Mapas from './components/mapas';
import ListaCancionRegalo from './components/listaCancionRegalo'; 
import Confirmacion from './components/confirmacion';

function App() {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <NavBar />
          <ConteoRegresivo />
          <ProgramacionDia />
          <Mapas />
          <ListaCancionRegalo />
          <Confirmacion />
        </header>
      </div>
    </div>
  );
}

export default App;
