import React, { useEffect, useRef, useState }  from 'react';
import './confirmacion.css'; 
import florizd from '../img/ramaizq.png';  
import florder from '../img/ramader.png';
import Formulario from './formulario';

function Confirmacion() {

    const [mostrarFormulario, setMostrarFormulario] = useState(false);

    const abrirFormulario = () => {
        setMostrarFormulario(true);
    };

    const formularioRef = useRef(null);

    useEffect(() => {
        if (mostrarFormulario && formularioRef.current) {
            formularioRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mostrarFormulario]);

    return (
        <>
            <div className="confirmacion-container">
                <img src={florizd} alt="Flower Left" className="flower" />
                <div className="confirmacion-content">
                    <p className="confirmacion-text">¡Nos encantaría contar con tu presencia en nuestro día especial! Por favor, confirmá tu asistencia.</p>
                    <button className="confirmacion-button" onClick={abrirFormulario}>Confirmar</button>
                </div>
                <img src={florder} alt="Flower Right" className="flower" />
            </div>
            <div ref={formularioRef}>
                {mostrarFormulario && <Formulario />}
            </div>
        </>
    );
}

export default Confirmacion;