import React, {useState} from 'react';
import './formulario.css';
import flowerImage from '../img/florderecha.png';

function Formulario() {
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        dni: '',
        menu: ''
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');

    // Actualiza el estado con los valores de los inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateInput(name, value); // Validar cada input cuando cambia
    };

    // Validar entradas específicas
    const validateInput = (name, value) => {
        let errorMsg = '';
        if (name === 'nombre' || name === 'apellido') {
            if (!/^[a-zA-Z\s]+$/.test(value)) {
                errorMsg = 'Solo se permite el ingreso de letras';
            }
        } else if (name === 'dni') {
            if (!/^\d+$/.test(value)) {
                errorMsg = 'Solo se permite el ingreso de números';
            }
        }

        setErrors(prev => ({
            ...prev,
            [name]: errorMsg
        }));

        // También verifica si el error debería eliminarse porque el campo ha sido corregido
        if (errorMsg === '') {
            setErrors(prev => ({
                ...prev,
                [name]: ''  // Limpia el mensaje de error si el campo ahora es válido
        }));
    }
    };
    
    // Maneja el envío del formulario
    const handleSubmit = async (e) => {
        const apiUrl = 'https://www.elsidemaruynico.com.ar';
        e.preventDefault(); // Previene la recarga de la página

        // Validación final antes de enviar
        if (!formData.nombre || !formData.apellido || !formData.dni || !formData.menu || Object.values(errors).some(err => err !== '')) {
            setMessage('Por favor, completa todos los campos correctamente.');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/invitados`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (data.error) {
                setMessage('Error al guardar los datos: ' + data.message);
            } else {
                setShowModal(true);
                // Limpia el formulario
                setFormData({
                    nombre: '',
                    apellido: '',
                    dni: '',
                    menu: ''
                });
                setMessage('');
            }
        } catch (error) {
            console.error('Error al guardar los datos:', error);
            setMessage('Error al conectar con el servidor.');
        }
    };

    const [showModal, setShowModal] = useState(false);
    const Modal = ({ onClose }) => (
        <div className="modal-background">
            <div className="modal-formulario">
                <button onClick={onClose}>x</button>
                <h2>¡Gracias por confirmar!</h2>
                <p>Recuerda enviar el comprobante de pago a los siguientes números:</p>
                <p>Maru: +54 9 3492 66-4375</p>
                <p>Nico: +54 9 3492 56-6990</p>
                <p>¡Preparate para bailar toda la noche!</p>
            </div>
        </div>
    );

    // Cerrar el modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
        <div className="formulario-container">
            <div className="info-section">
                <div className='info'>
                    <p className="info-text1"> <strong>Valor tarjeta</strong> </p>
                    <p className="info-text"> Menores (de 4 a 10 años): <strong>$30000</strong></p>
                    <p className="info-text">Mayores (de 11 años en adelante): <strong>$60000</strong></p>
                    <div className="line"></div>
                    <p className="info-text1"><strong>Datos Cuenta Bancaria: </strong></p>
                    <p className="info-text"><strong>Titular:</strong> Marina Rosana Aimo</p>
                    <p className="info-text"><strong>CVU:</strong> 0000003100056142119874</p>
                    <p className="info-text"><strong>Alias:</strong> Boda.Maru-Nico</p>
                    <div className="line"></div>
                    <p className="fecha-text"><strong>Fecha límite de pago 11/11</strong></p>
                </div>
            </div>
            <div className="form-section">
                <h2 className='asistencia'>Confirmá tu asistencia</h2>
                <input className="inputform" type="text" placeholder="Nombre"  name="nombre" value={formData.nombre} onChange={handleChange}/>
                {errors.nombre && <p className="error-message">{errors.nombre}</p>}
                <input className="inputform" type="text" placeholder="Apellido"  name="apellido" value={formData.apellido} onChange={handleChange}/>
                {errors.apellido && <p className="error-message">{errors.apellido}</p>}
                <input className="inputform" type="text" placeholder="DNI"  name="dni" value={formData.dni} onChange={handleChange}/>
                {errors.dni && <p className="error-message">{errors.dni}</p>}
                <p className='opcion'>Queremos que disfrutes de la comida en nuestro gran día, por favor secciona tu tipo de menú </p>
                <select className='selectform'  name="menu" value={formData.menu} onChange={handleChange}>
                    <option value="">Seleccionar menú</option>
                    <option value="Tradicional">Tradicional</option>
                    <option value="Vegano">Vegano</option>
                    <option value="Vegetariano">Vegetariano</option>
                    <option value="SIN TACC">SIN TACC</option>
                </select>
                <button className="confirmar" type="button" onClick={handleSubmit}>CONFIRMAR</button>
                <p className="confir-text"><strong>Confirmar antes del 04/11</strong></p>
                {showModal && <Modal onClose={handleCloseModal} />}
                {message && <p className={`response-message ${message.startsWith('Error') ? 'error-message' : ''}`}>{message}</p>} 
            </div>
        </div>
        </>
    );
}
export default Formulario;