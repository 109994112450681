import React, { useState } from 'react';
import './listaCancionRegalo.css'; 
import florCentro from '../img/florcentro.png';  
import regalo from '../img/regalo.png';  
import spoty from '../img/spoty.png';  

function ListaCancionRegalo() {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const irSpotify = (url) => {
        window.open(url)
    }

    return (
        <div className="lista-container">
            <div className="lista-item">
                <img src={spoty} alt="Icono Spotify" className="lista-icono"/>
                <p className="lista-description">Queremos compartir una noche mágica contigo, y nos encantaría saber qué canciones no pueden faltar en nuestra boda.</p>
                <button className="lista-button" onClick={() => irSpotify("https://open.spotify.com/playlist/1aElRZfse0VgiXfjgU3gCG?si=ee977aad2dc74303")}>Ir a Spotify</button>
            </div>
            <img src={florCentro} alt="Decoración" className="lista-centro"/> 
            <div className="lista-item">
                <img src={regalo} alt="Icono Regalo" className="lista-icono"/>
                <p className="lista-description">Tu presencia es nuestro mejor regalo, pero si quieres hacernos un presente puedes ayudarnos con nuestra luna de miel.</p>
                <button className="lista-button" onClick={handleOpenModal}>Regalo</button>
            </div>
            {showModal && (
                <div className="modal" onClick={handleCloseModal}>
                    <div className="modal-content">
                        <button onClick={handleCloseModal}>x</button>
                        <h2>Datos de la cuenta:</h2>
                        <p>Titular: Nicolas Oscar Villarruel</p>
                        <p>CVU: 0000003100098082567377</p>
                        <p>Alias: Lunademiel.Nym</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListaCancionRegalo;