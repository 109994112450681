import React from 'react';
import './mapas.css'; 
import Iglesia from '../img/iglesia_.png';  
import civil from '../img/arcodeboda.png';  

function Mapas() {
    
    const irMapa = (url) => {
        window.open(url)
    }

    return (
        <div className="mapas-container">
            <div className="mapas-item">
                <img src={Iglesia} alt="Flor decorativa" className="iconoMapa"/>
                <p className="mapas-description"> La celebración religiosa será en la Iglesia San Cayetano.</p> 
                <p className="mapas-description"><strong>Azcuénaga 1351</strong></p>
                <button className="mapas-button" onClick={() => irMapa("https://www.google.com/maps/place/PARROQUIA+SAN+CAYETANO/@-31.2642103,-61.5099818,19.5z/data=!4m6!3m5!1s0x95caae2bb7f0eeeb:0x9b281924b7c11c14!8m2!3d-31.2643489!4d-61.5099845!16s%2Fg%2F11bz0cdc7v?hl=es&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D")}>Ir a Mapa</button>
            </div>
            <div className="mapas-item">
                <img src={civil} alt="Flor decorativa" className="iconoMapa"/>
                <p className="mapas-description"> El civil y la fiesta se realizará en el salón de la UOM, Bella Italia.</p>
                <p className="mapas-description"> <strong>Los Ceibos 2236</strong></p>
                <button className="mapas-button" onClick={() => irMapa("https://www.google.com/maps/place/31%C2%B016'03.5%22S+61%C2%B025'44.9%22W/@-31.2676158,-61.4292059,20.5z/data=!4m13!1m8!3m7!1s0x95caaf3709800f25:0xd34c428e3835d9a1!2sLos+Ceibos,+S2301,+Santa+Fe!3b1!8m2!3d-31.2674218!4d-61.4296522!16s%2Fg%2F11fkr37b69!3m3!8m2!3d-31.267642!4d-61.429139?hl=es&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D")}>Ir a Mapa</button>
            </div>
        </div>
    );
}

export default Mapas;



