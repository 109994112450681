import React from 'react';
import './navBar.css';
import caracara from '../img/caracara.jpg';  
import flor from '../img/flor.png';  

function NavBar() {
  return (
    <div className="container">
      <div className="navbar">
        <h1 className="nosCasamos">
          <p>Nos casamos</p>
        </h1>
      </div>
      <div className="header-content">
        <div className="title-container">
          <h1 className="title">Maru</h1>
          <h1 className="and">&</h1>
          <h1 className="title">Nico</h1>
          <p className="date">16 DE NOVIEMBRE | 2024</p>
          <img src={flor} alt="Flor" className="flor-image"/>
        </div>
        <div className="image-container">
          <img src={caracara} alt="Maru y Nico" className="circular-image"/>
        </div>
      </div>
      <div className="description">
        <p>
          Valientes corazones con la convicción de que es el amor el que hace la revolución
        </p>
      </div>
    </div>
  );
}

export default NavBar;